import React from "react";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";
import moment from "moment";

import Layout from "../../components/layout";
import PageInfo from "../../components/pageInfo";
import RenderRichText from "../../components/renderRichText";
import ImageFrame from "../../components/imageFrame";
import Letter from "../../components/letter";
// import TimeToRead from "../../components/timeToRead";

const Index = ({
  data: { contentfulPost },
  pageContext: { previousPost, nextPost },
}) => {
  let options = {
    renderFuncs: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <ImageFrame
          key={node.data.target.title}
          image={getImage(node.data.target.gatsbyImageData)}
          alt={node.data.target.title}
          className="text-black"
          style={{ backgroundColor: contentfulPost.accentColor }}
          caption={node.data.target.description}
        />
      ),
    },
  };

  return (
    <Layout>
      <PageInfo title={contentfulPost.title} />

      {/* <div className="container">
        <Link to="/blog/" className="mb-3">
          ← Back to Blogs
        </Link>
        <hr className="mt-6 mb-10" />
      </div> */}

      <Letter className="mx-auto py-14 px-10 max-w-screen-lg">
        <h1 className="text-5xl text-center mb-6 font-new-tegomin">
          {contentfulPost.title}
        </h1>

        <RenderRichText richText={contentfulPost.body} options={options} />

        <div className="flex gap-2 flex-wrap mb-6 text-sm">
          {contentfulPost.metadata.tags.map((tag, i) => (
            <span
              key={i}
              className="bg-gray-200 text-studio-charcoal rounded px-2 py-1"
            >
              {tag.name}
            </span>
          ))}
        </div>

        <div className="mb-6">
          <span className="inline-block w-full">
            {moment(contentfulPost.createdAt).format("MMM DD, YYYY")}
            {contentfulPost.updatedAt && (
              <> &sdot; Edited {moment(contentfulPost.updatedAt).fromNow()}</>
            )}
          </span>
        </div>

        <hr className="my-6 border-studio-charcoal" />
        <div
          className="grid gap-6"
          style={{ gridTemplateColumns: "1fr auto 1fr" }}
        >
          <div>
            {previousPost ? (
              <Link to={previousPost.slug} className="mb-3 font-bold">
                {previousPost.title}
              </Link>
            ) : (
              <span className="sr-only">Previous Post Not Available</span>
            )}
          </div>
          <Link to="/blog/" className="mb-3 font-bold">
            All Blogs
          </Link>
          <div className="text-right">
            {nextPost ? (
              <Link to={nextPost.slug} className="mb-3 font-bold">
                {nextPost.title}
              </Link>
            ) : (
              <span className="sr-only">Next Post Not Available</span>
            )}
          </div>
        </div>
      </Letter>
    </Layout>
  );
};

export const query = graphql`
  query ($contentful_id: String!) {
    contentfulPost(contentful_id: { eq: $contentful_id }) {
      ...PostTemplateFields
    }
  }
`;

export default Index;
